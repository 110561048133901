import React, { useState } from "react";
import {
  Box,
  IconButton,
  TextField,
  Typography,
  Collapse,
  Avatar,
  Tooltip,
  Badge,
} from "@mui/material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { SummeryComment } from "../Constants/interface";
import { TranscriptMetadata } from "./Services/fetchService";
import {
  CommentContainer,
  CommentSection,
  AddCommentButton,
  CommentHeader,
} from "./ControlStyles";
import { addPostToDatabase } from "../ApiFunctions/addToDatabase";
import { WEBSITE } from "../Constants/consts";

interface CommentsProps {
  comments: SummeryComment[];
  lectureId: string;
  setComments: React.Dispatch<React.SetStateAction<SummeryComment[]>>;
  lectureMetadata: TranscriptMetadata;
}

const Comments: React.FC<CommentsProps> = ({
  comments,
  lectureId,
  setComments,
  lectureMetadata,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [isComposing, setIsComposing] = useState(false);
  const filteredComments = comments.filter(
    (comment) => comment.lectureId === lectureId && comment.content
  );
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const newCommentObj: SummeryComment = {
      content: newComment,
      author: "",
      lectureId: lectureId,
      timestamp: new Date().toISOString(),
      commentedToAnother: undefined,
      lectureMetadata: {
        lectureId: lectureId,
        subject: lectureMetadata.subject,
        name: lectureMetadata.name,
        filename: lectureMetadata.filename,
      },
    };
    addPostToDatabase({ ...newCommentObj }, "TranscriptionSummeries", WEBSITE);
    setComments((prev) => [...prev, newCommentObj]);
    setNewComment("");
    setIsComposing(false);
  };

  return (
    <CommentContainer>
      <CommentHeader>
        <Typography
          variant="subtitle1"
          sx={{
            color: "rgba(255, 255, 255, 0.9)",
            fontWeight: 500,
            ml: 1,
          }}
        >
          תגובות
        </Typography>
        <Tooltip title={`${filteredComments.length} תגובות`}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={() => setIsExpanded(!isExpanded)}
              sx={{
                color: "rgba(255, 255, 255, 0.9)",
                transform: isExpanded ? "rotate(180deg)" : "none",
                transition: "all 0.3s ease",
              }}
            >
              <Badge
                badgeContent={filteredComments.length ?? 0}
                color="primary"
                sx={{
                  "& .MuiBadge-badge": {
                    backgroundColor: "primary.main",
                    color: "rgba(255, 255, 255, 0.87)",
                    fontWeight: "bold",
                    minWidth: "20px",
                    height: "20px",
                    borderRadius: "10px",
                  },
                }}
              >
                <ChatBubbleOutlineIcon
                  sx={{ color: "rgba(255, 255, 255, 0.9)" }}
                />
              </Badge>
            </IconButton>
          </Box>
        </Tooltip>
      </CommentHeader>

      <Collapse in={isExpanded} timeout="auto">
        <CommentSection>
          {filteredComments.map((comment, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                gap: 2,
                mb: 2,
                opacity: 0,
                animation: "fadeIn 0.3s ease forwards",
                animationDelay: `${index * 0.1}s`,
                "@keyframes fadeIn": {
                  from: { opacity: 0, transform: "translateY(10px)" },
                  to: { opacity: 1, transform: "translateY(0)" },
                },
              }}
            >
              <Avatar sx={{ bgcolor: "primary.main" }}>
                {comment.author?.[0]?.toUpperCase() || "A"}
              </Avatar>
              <Box sx={{ flex: 1 }}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: "bold",
                    color: "rgba(255, 255, 255, 0.9)",
                    mb: 0.5,
                  }}
                >
                  {comment.author || "Anonymous"}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    whiteSpace: "pre-wrap",
                    color: "rgba(255, 255, 255, 0.7)",
                    lineHeight: 1.5,
                    fontSize: "0.9rem",
                  }}
                >
                  {comment.content}
                </Typography>
              </Box>
            </Box>
          ))}

          {isComposing ? (
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                multiline
                rows={3}
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                placeholder="הוסף תגובה..."
                variant="outlined"
                sx={{
                  mb: 1,
                  "& .MuiOutlinedInput-root": {
                    color: "rgba(255, 255, 255, 0.9)",
                    "& fieldset": {
                      borderColor: "rgba(255, 255, 255, 0.23)",
                    },
                    "&:hover fieldset": {
                      borderColor: "rgba(255, 255, 255, 0.4)",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "rgba(255, 255, 255, 0.7)",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "rgba(255, 255, 255, 0.7)",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "rgba(255, 255, 255, 0.5)",
                  },
                }}
              />
              <AddCommentButton
                onClick={handleSubmit}
                disabled={!newComment.trim()}
              >
                שלח תגובה
              </AddCommentButton>
            </Box>
          ) : (
            <AddCommentButton
              onClick={() => setIsComposing(true)}
              sx={{ mt: 2 }}
            >
              הוסף תגובה
            </AddCommentButton>
          )}
        </CommentSection>
      </Collapse>
    </CommentContainer>
  );
};

export default Comments;
