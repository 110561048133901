import ReactGA from "react-ga4";

const TRACKING_ID = "G-0KN537307H"; // Replace with your Measurement ID
ReactGA.initialize(TRACKING_ID);

export const logPageView = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
  });
};

export const logEvent = (eventName: string, params: { [key: string]: any }) => {
  ReactGA.event(eventName, params);
};

export const logCustomEvent = (
  eventName: string,
  params: { [key: string]: any }
) => {
  const flattenedParams: { [key: string]: string | number } = {};
  for (const key in params) {
    if (params[key] instanceof Object) {
      flattenedParams[key] = JSON.stringify(params[key]);
    } else {
      flattenedParams[key] = params[key];
    }
  }

  ReactGA.event(eventName, flattenedParams);
};
