import { Margin } from "@mui/icons-material";
import { Box, Paper, ToggleButtonGroup, styled } from "@mui/material";

// Responsive constants
export const SIZES = {
  xs: {
    PADDING: {
      button: 4,
      container: 0,
      group: 8,
      paper: 0,
    },
    ICON: 1.2,
    GAP: 4,
  },
  sm: {
    PADDING: {
      button: 6,
      container: 6,
      group: 12,
      paper: 2,
    },
    ICON: 1.4,
    GAP: 6,
  },
  md: {
    PADDING: {
      button: 8,
      container: 8,
      group: 16,
      paper: 5,
    },
    ICON: 1.6,
    GAP: 8,
  },
};

export const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(SIZES.xs.PADDING.paper),
  marginBottom: theme.spacing(2),
  background: "rgba(255, 255, 255, 0.08)",
  borderRadius: 24,
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
  backdropFilter: "blur(12px)",
  marginTop: theme.spacing(2),
  border: "1px solid rgba(255, 255, 255, 0.12)",
  transition: "all 0.3s ease",

  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(SIZES.xs.PADDING.paper),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(SIZES.sm.PADDING.paper),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(SIZES.md.PADDING.paper),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },

  "&:hover": {
    boxShadow: "0 12px 40px rgba(0, 0, 0, 0.15)",
  },
}));

export const ControlContainer = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  zIndex: theme.zIndex.appBar,
  padding: theme.spacing(1.5),
  display: "flex",
  flexWrap: "wrap",
  gap: SIZES.xs.GAP,
  alignItems: "center",
  background: "rgba(18, 18, 18, 0.45)",
  backdropFilter: "blur(10px)",
  borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
  transition: "all 0.3s ease",

  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
    gap: SIZES.xs.GAP / 2,
  },
}));

export const ControlGroup = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: SIZES.xs.GAP,
  padding: `${SIZES.xs.PADDING.button}px ${SIZES.xs.PADDING.group}px`,
  background: "rgba(0, 0, 0, 0.2)",
  borderRadius: "10px",
  border: "1px solid rgba(255, 255, 255, 0.1)",
  flexShrink: 0,
  transition: "background-color 0.2s ease",

  "&:hover": {
    background: "rgba(0, 0, 0, 0.25)",
  },

  "&:has(.Mui-selected)": {
    background: "rgba(0, 0, 0, 0.35)",
    borderColor: "rgba(255, 255, 255, 0.2)",
  },

  [theme.breakpoints.down("sm")]: {
    "& .MuiToggleButton-root": {
      padding: `${SIZES.xs.PADDING.button}px ${SIZES.xs.PADDING.group}px`,
    },
    "& .MuiIconButton-root": {
      padding: SIZES.xs.PADDING.button,
    },
    "& .MuiSvgIcon-root": {
      fontSize: `${SIZES.xs.ICON}rem`,
    },
  },

  [theme.breakpoints.up("sm")]: {
    gap: SIZES.sm.GAP,
    padding: `${SIZES.sm.PADDING.button}px ${SIZES.sm.PADDING.group}px`,
    "& .MuiToggleButton-root": {
      padding: `${SIZES.sm.PADDING.button}px ${SIZES.sm.PADDING.group}px`,
    },
    "& .MuiIconButton-root": {
      padding: SIZES.sm.PADDING.button,
    },
    "& .MuiSvgIcon-root": {
      fontSize: `${SIZES.sm.ICON}rem`,
    },
  },

  [theme.breakpoints.up("md")]: {
    gap: SIZES.md.GAP,
    padding: `${SIZES.md.PADDING.button}px ${SIZES.md.PADDING.group}px`,
    "& .MuiToggleButton-root": {
      padding: `${SIZES.md.PADDING.button}px ${SIZES.md.PADDING.group}px`,
    },
    "& .MuiIconButton-root": {
      padding: SIZES.md.PADDING.button,
    },
    "& .MuiSvgIcon-root": {
      fontSize: `${SIZES.md.ICON}rem`,
    },
  },
}));

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    gap: SIZES.xs.GAP,
    height: "100%",

    "& .MuiToggleButton-root": {
      border: "1px solid rgba(255, 255, 255, 0.15)",
      borderRadius: "10px !important",
      color: "rgba(255, 255, 255, 0.7)",
      transition: "all 0.2s ease",

      [theme.breakpoints.down("sm")]: {
        padding: `${SIZES.xs.PADDING.button}px ${SIZES.xs.PADDING.group}px`,
        "& .MuiSvgIcon-root": {
          fontSize: `${SIZES.xs.ICON}rem`,
        },
      },

      [theme.breakpoints.up("sm")]: {
        padding: `${SIZES.sm.PADDING.button}px ${SIZES.sm.PADDING.group}px`,
        "& .MuiSvgIcon-root": {
          fontSize: `${SIZES.sm.ICON}rem`,
        },
      },

      [theme.breakpoints.up("md")]: {
        padding: `${SIZES.md.PADDING.button}px ${SIZES.md.PADDING.group}px`,
        margin: `0 ${SIZES.md.PADDING.group}px 0 0`,
        "& .MuiSvgIcon-root": {
          fontSize: `${SIZES.md.ICON}rem`,
        },
      },

      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.15)",
        transform: "translateY(-1px)",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.15)",
      },

      "&.Mui-selected": {
        backgroundColor: "rgba(255, 255, 255, 0.25)",
        color: "rgba(255, 255, 255, 0.95)",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
        borderColor: "rgba(255, 255, 255, 0.3)",

        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.3)",
          boxShadow: "0 6px 16px rgba(0, 0, 0, 0.25)",
        },
      },

      "& .MuiSvgIcon-root": {
        transition: "transform 0.2s ease",
      },

      "&:hover .MuiSvgIcon-root": {
        transform: "scale(1.1)",
      },

      "&.Mui-selected .MuiSvgIcon-root": {
        transform: "scale(1.1)",
      },
    },
  })
);

export const CommentContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  transition: "all 0.3s ease",

  [theme.breakpoints.down("sm")]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export const CommentSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  marginTop: theme.spacing(1),
  background: "rgba(255, 255, 255, 0.05)",
  borderRadius: theme.shape.borderRadius,
  border: "1px solid rgba(255, 255, 255, 0.1)",
  backdropFilter: "blur(10px)",
}));

export const AddCommentButton = styled("button")(({ theme }) => ({
  background: "rgba(255, 255, 255, 0.1)",
  border: "1px solid rgba(255, 255, 255, 0.2)",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1, 2),
  color: "rgba(255, 255, 255, 0.9)",
  cursor: "pointer",
  transition: "all 0.2s ease",
  fontWeight: 500,

  "&:hover": {
    background: "rgba(255, 255, 255, 0.15)",
    transform: "translateY(-1px)",
  },

  "&:disabled": {
    opacity: 0.5,
    cursor: "not-allowed",
    transform: "none",
  },
}));

export const CommentHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  border: "1px solid rgba(255, 255, 255, 0.1)",
  marginBottom: theme.spacing(1),
  backdropFilter: "blur(10px)",

  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(0.5, 1),
  },
}));

export const MainContentWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  position: "relative",
  minHeight: "100vh",

  [theme.breakpoints.up("lg")]: {
    paddingRight: "320px", // Space for TOC
  },
}));

export const ContentArea = styled(Box)(({ theme }) => ({
  flex: 1,
  transition: "all 0.3s ease",

  [theme.breakpoints.up("lg")]: {
    marginRight: "-320px", // Compensate for TOC space
  },
}));

export const TOCWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: theme.zIndex.drawer,

  // Add backdrop blur when TOC is open
  "&::before": {
    content: '""',
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    width: "320px",
    background: "rgba(0, 0, 0, 0.2)",
    backdropFilter: "blur(8px)",
    opacity: 0,
    transition: "opacity 0.3s ease",
    pointerEvents: "none",
  },

  '&[data-open="true"]::before': {
    opacity: 1,
  },
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
  paddingTop: "120px",

  [theme.breakpoints.down("sm")]: {
    paddingTop: "140px",
  },
}));
