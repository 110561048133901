import { co } from "@fullcalendar/core/internal-common";
import {
  AnswerComment,
  RawTranscription,
  RawTranscriptionSegment,
  SummeryComment,
  Term,
  Transcription,
} from "../../Constants/interface";
const localBaseUrl = "http://localhost:7071/api";
const baseUrl = "https://transcriptions-function.azurewebsites.net/api";
const updateUrl = `${localBaseUrl}/updatetranscriptinfile`;
const isLocal = false;
const url = isLocal ? localBaseUrl : baseUrl;
const getTranscriptUrl = `${url}/gettranscriptsbyfileid`;
const getTranscriptPageUrl = `${url}/paginationgettranscript`;
const getTranscriptMetadataUrl = `${url}/gettranscriptsbyfileid?fileId=metadata`;
const getBlobNames = `${url}/getBlobNamesFromContainer`;
const directDatabaseBaseUrl = `https://transcriptionsdata.blob.core.windows.net`;
export interface TranscriptMetadata {
  filename: string;
  name: string;
  subject: string;
  audioSrc: string;
  id?: string;
}
export interface RawTranscriptMetadata {
  filename: string;
  name: string;
  subject: string;
  audio_src: string;
  id?: string;
}

function convertRawTranscriptMetadata(
  rawTranscriptMetadata: RawTranscriptMetadata
): TranscriptMetadata {
  return {
    filename: rawTranscriptMetadata.filename,
    name: rawTranscriptMetadata.name,
    subject: rawTranscriptMetadata.subject,
    audioSrc: rawTranscriptMetadata.audio_src,
    id: rawTranscriptMetadata.id,
  };
}

export async function getTranscriptMetadata() {
  console.log("fetching metadata");
  const response = await fetch(getTranscriptMetadataUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const rawData: RawTranscriptMetadata[] = await response.json();
  const data = rawData.map(convertRawTranscriptMetadata);
  return data;
}

export async function getAllBlobNames(container: string = "") {
  const response = await fetch(`${getBlobNames}?container=${container}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    console.error(`HTTP error! status: ${response.status}`);
  }
  return response.json();
}

export async function getTranscript(fileId: string): Promise<Transcription> {
  const response = await fetch(`${getTranscriptUrl}?fileId=${fileId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    console.error(`HTTP error! status: ${response.status}`);
  }
  const rawTranscription = await response.json();
  const formattedTranscription =
    formatTrancsriptTimeToSeconds(rawTranscription);
  return formattedTranscription;
}

export async function updateTranscript(
  fileId: string,
  transcriptId: string,
  updatedText: string,
  userId: string
): Promise<string> {
  const response = await fetch(`${updateUrl}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      fileId: fileId,
      transcriptId: transcriptId,
      updatedText: updatedText,
    }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function getTranscriptPage(
  fileId: string,
  page: number,
  pageSize: number
): Promise<Transcription> {
  const response = await fetch(
    `${getTranscriptPageUrl}?fileId=${fileId}&page=${page}&pageSize=${pageSize}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function getJson(url: string) {
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return response.json();
}

export async function getTranscriptMetadataDirectly(
  filename: string = "metadata.json"
): Promise<TranscriptMetadata[]> {
  const encodedFilename = encodeURIComponent(filename);
  const timestamp = new Date().getTime(); // Generate a timestamp
  const url = `${directDatabaseBaseUrl}/transcripts/${encodedFilename}?t=${timestamp}`;

  console.log("fetching metadata", url);

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache, no-store, must-revalidate",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const rawData: RawTranscriptMetadata[] = await response.json();
  const data = rawData.map(convertRawTranscriptMetadata);
  console.log(data);
  return data;
}

export async function getTranscriptDirectly(
  fileId: string
): Promise<Transcription> {
  const response = await fetch(
    `${directDatabaseBaseUrl}/transcripts/${fileId}.json`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    console.error(`HTTP error! status: ${response.status}`);
  }

  const rawTranscription = await response.json();
  const formattedTranscription =
    formatTrancsriptTimeToSeconds(rawTranscription);
  return formattedTranscription;
}

export function timeStringToSeconds(timeString: string): number {
  const [hours, minutes, seconds] = timeString.split(":").map(parseFloat);
  return hours * 3600 + minutes * 60 + seconds;
}

function formatTrancsriptTimeToSeconds(
  Transcription: RawTranscription
): Transcription {
  const formattedTranscription = Transcription.TranscriptionText.map(
    (segment: RawTranscriptionSegment) => ({
      ...segment,
      start: timeStringToSeconds(segment.start),
      end: timeStringToSeconds(segment.end),
    })
  );
  Transcription.TranscriptionSummary = Transcription.TranscriptionSummary
    ? formatTextWithMathAndPreserveTimestamps(
        Transcription.TranscriptionSummary
      )
    : undefined;

  let formattedTranscriptShortSummery = Transcription.ShortTranscriptionSummary
    ? Transcription.ShortTranscriptionSummary
    : undefined;

  let terms: Term[] = [];
  if (formattedTranscriptShortSummery) {
    terms = parseTerms(formattedTranscriptShortSummery);
  }

  if (formattedTranscriptShortSummery) {
    formattedTranscriptShortSummery = formattedTranscriptShortSummery
      .replace(/(?<!\d):(?!\d)/g, "")
      .replace(/(?<!\d)\$(?!\d)/g, "")
      .replace(/\$(.*?)\$/g, '"$1"');
  }

  return {
    ...Transcription,
    TranscriptionText: formattedTranscription,
    ShortTranscriptionSummary: formattedTranscriptShortSummery,
    Terms: terms,
  };
}

export function parseTerms(text: string): Term[] {
  console.log("text", text);
  const termBlocks = text.split(/####\s+/).slice(1); // Skip the first empty element
  return termBlocks.map((block) => {
    const lines = block.trim().split("\n");
    const term = lines[0].replace(/\$/g, "").trim();

    let definition = "";
    let relatedTerms: string[] = [];
    let appearances: string[] = [];

    lines.slice(1).forEach((line) => {
      line = line.replace(/\$/g, "").trim();
      line = line.replace(/\&/g, "").trim();
      if (line.startsWith("**הסבר**:")) {
        definition = line.split(":")[1].trim();
      } else if (line.startsWith("**מונחים קשורים**:")) {
        relatedTerms = line
          .split(":")[1]
          .trim()
          .split(", ")
          .map((t) => t.trim());
      } else if (line.startsWith("**הופעות**:")) {
        const match = line.match(/\[(.*?)\]/);
        if (match) {
          appearances = match[1].split(", ");
        }
      }
    });

    return { term, definition, relatedTerms, appearances };
  });
}

function formatTextWithMathAndPreserveTimestamps(text: string) {
  const mathRegex = /(\([^)]+\)|\[[^\]]+\])/g;

  const parts = text.split(/(\(\d{2}:\d{2}:\d{2}\))/);

  // Process each part to encapsulate math expressions with $$
  const formattedParts = parts.map((part) => {
    if (!part.match(/\(\d{2}:\d{2}:\d{2}\)/)) {
      // Not a timestamp, process for math expression
      return part.replace(mathRegex, (match, p1) => {
        return `<div class="math-expression">$$${p1}$$</div>`;
      });
    }
    return part; // Preserve timestamps as-is
  });

  // Join parts back together to form the formatted text
  const formattedText = formattedParts.join("");
  return text;
}

export const fetchCommentsFromDatabase = async () => {
  try {
    const response = await fetch(
      `https://database-handling.azurewebsites.net/api/get_posts?container=TranscriptionSummeries`
    );
    const data: SummeryComment[] = await response.json();
    console.log("Fetched comments:", data);
    return data;
  } catch (error) {
    console.error("Error fetching comments:", error);
  }
};
