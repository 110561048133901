import { BorderRight, Margin, Padding } from "@mui/icons-material";
import { letterSpacing } from "html2canvas/dist/types/css/property-descriptors/letter-spacing";
import { listStyleType } from "html2canvas/dist/types/css/property-descriptors/list-style-type";

export const createStyles = (
  factor: number,
  long: boolean = false,
  toPDF: boolean = false
) => ({
  markdownParagraphWithImage: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "flex-start",
    gap: "20px",
    marginBottom: "20px",
  },

  markdownTextBody: {
    marginRight: toPDF ? "5px" : "35px",
    fontSize: {
      xs: `${0.9 * factor}em`,
      md: `${0.9 * factor}em`,
    },
    flex: 1,
    "& p": {
      marginTop: 0,
    },
  },

  markdownImageWrapper: {
    flexShrink: 0,
    maxWidth: "100%", // Adjust as needed
  },

  markdownImageContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    margin: "20px 0 20px 0",
  },

  markdownImage: {
    width: { xs: "100%", md: "50%" },
    height: "auto",
    borderRadius: "4px",
  },

  markdownCaption: {
    letterSpacing: "0px",
    fontSize: {
      xs: `${0.7 * factor}em`,
      md: `${0.7 * factor}em`,
    },
    textAlign: "right",
    marginTop: "8px",
    width: { xs: "100%", md: "50%" },
    fontWeight: "bold",
  },

  h3: {
    marginRight: toPDF ? "5px" : "30px",
    fontWeight: 700,
    fontSize: {
      xs: `${1.2 * factor}em`,
      md: `${1.5 * factor}em`,
    },
    color: "#1a237e", // Deep blue color
    marginTop: toPDF ? "24px" : "12px",
    textTransform: "uppercase",
    borderRight: "5px solid #283593",
    paddingRight: "12px",
  },

  h4: {
    marginRight: toPDF ? "5px" : "45px",

    fontWeight: 600,
    fontSize: {
      xs: `${1.18 * factor}em`,
      md: `${1.4 * factor}em`,
    },
    color: "rgb(18, 83, 182)", // Slightly lighter blue
    marginTop: toPDF ? "20px" : "12px",

    paddingRight: "12px",
    borderRight: "4px solid rgb(18, 83, 182)",
  },

  h5: {
    marginRight: toPDF ? "5px" : "55px",

    fontWeight: 600,
    fontSize: {
      xs: `${1.12 * factor}em`,
      md: `${1.3 * factor}em`,
    },
    color: "rgb(179, 2, 255)", // Slightly lighter blue
    marginTop: toPDF ? "20px" : "12px",
    paddingRight: "12px",
    borderRight: "4px solid rgb(179, 2, 255)",
  },

  h6: {
    marginRight: toPDF ? "5px" : "65px",

    fontWeight: 600,
    fontSize: {
      xs: `${1.1 * factor}em`,
      md: `${1.1 * factor}em`,
    },
    color: "rgb(18, 83, 182)", // Slightly lighter blue
    marginTop: "20px",
    paddingRight: "12px",
    borderRight: "4px solid rgb(18, 83, 182)",
  },

  markdownContainer: {
    marginRight: toPDF ? "7%" : "35px",
    display: "flex",
    direction: "rtl" as const,
    alignItems: "right",
    fontSize: {
      xs: `${1 * factor}em`,
      md: `${0.9 * factor}em`,
    },
    flexDirection: long ? { xs: "column", md: "row !important" } : "column",
  },

  markdownListOL: {
    fontSize: {
      xs: `${0.8 * factor}em`,
      md: `${0.8 * factor}em`,
    },
    marginRight: toPDF ? "3%" : "55px",

    listStyleType: "square",
  },

  markdownListUL: {
    fontSize: {
      xs: `${0.8 * factor}em`,
      md: `${0.8 * factor}em`,
    },
    marginRight: toPDF ? "7%" : "55px",
    listStyleType: "circle",
  },

  markdownNestedListContainer: {
    marginRight: toPDF ? "5px" : "55px",
    padding: 0,
    marginTop: toPDF ? "4px" : "",
    listStyleType: "none",
    marginBottom: "8px",
  },

  markdownListItem: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    padding: "4px 0",
  },

  markdownNestedListItem: {
    paddingLeft: "20px",
    width: "100%",
  },

  markdownListItemIcon: {
    minWidth: "auto",
    marginLeft: "8px",
    marginTop: toPDF ? "4px" : "",
    color: "#1a237e",
  },

  markdownListItemText: {
    margin: 0,
    "& > *": {
      textAlign: "right",
    },
    "& p": {
      fontSize: {
        xs: `${1 * factor}em`,
        md: `${0.9 * factor}em`,
      },
      margin: 0,
    },
  },

  markdownTextContent: {
    flex: 1,
  },

  markdownBody: {
    direction: "rtl" as const,
    display: "flex",
    flexDirection: "row", // Always use row direction
    alignItems: "flex-start", // Align items to the top
    width: "100%",
    maxWidth: "1200px",
    margin: "0 auto",
    gap: "20px", // Add some space between image and text
  },

  markdownText: {
    flex: 1, // Allow text to take remaining space
  },

  markdownTermTextTitle: {
    fontSize: {
      xs: `${1 * factor}em`,
      md: `${1.1 * factor}em`,
    },
    fontWeight: "bold",
    paddingTop: "10px",
    paddingBottom: "10px",
  },

  markdownTermText: {
    fontSize: {
      xs: `${0.8 * factor}em`,
      md: `${1 * factor}em`,
    },
  },
});
