import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Box,
  Typography,
  Slider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  TextField,
  InputAdornment,
  Tooltip,
} from "@mui/material";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SearchIcon from "@mui/icons-material/Search";
import ImageIcon from "@mui/icons-material/Image";

import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

import {
  Transcription,
  TranscriptionSegment,
  RawTranscriptionSegment,
} from "../Constants/interface";
import { co } from "@fullcalendar/core/internal-common";
import { logCustomEvent } from "../utils/Analytics";

export interface TranscriptionPlayerProps {
  transcription: Transcription;
  playbackRate?: number;
}

interface ChunkedSegment {
  id: string;
  text: string;
  start: number;
  end: number;
  originalSegments: TranscriptionSegment[];
}

export function rawTranscriptionSegmentToTranscriptionSegment(
  rawSegment: RawTranscriptionSegment
): TranscriptionSegment {
  return {
    id: rawSegment.number.toString(),
    text: rawSegment.text,
    changedSentence: false,
    start: timeStringToSeconds(rawSegment.start),
    end: timeStringToSeconds(rawSegment.end),
    isImage: rawSegment.path !== undefined,
  };
}

export function timeStringToSeconds(timeString: string): number {
  const [hours, minutes, seconds] = timeString.split(":").map(parseFloat);
  return hours * 3600 + minutes * 60 + seconds;
}

const TranscriptionPlayer: React.FC<TranscriptionPlayerProps> = ({
  transcription,
}) => {
  const [playbackRate, setPlaybackRate] = useState(1);
  const [totalDuration, setTotalDuration] = useState(0);

  const [currentSegmentIndex, setCurrentSegmentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [visibleChunks, setVisibleChunks] = useState<ChunkedSegment[]>([]);
  const [chunkSize, setChunkSize] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const itemsPerPage = 10;
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const listRef = useRef<HTMLDivElement | null>(null);
  const observer = useRef<IntersectionObserver | null>(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<
    { index: number; matches: number[] }[]
  >([]);

  const searchInputRef = useRef<HTMLInputElement>(null);
  const [currentSearchIndex, setCurrentSearchIndex] = useState(-1);

  useEffect(() => {
    logCustomEvent("loaded_player", {
      subject: transcription.name,
      transcriptionName: transcription.filename,
    });
  }, [transcription]);

  useEffect(() => {
    console.log("TranscriptionPlayer, transcription: ", transcription.AudioSrc);
    //check is audio source end with mp3 or wav, if not, add mp3
    if (transcription.AudioSrc && !transcription.AudioSrc.endsWith(".mp3")) {
      transcription.AudioSrc += ".mp3";
    }
  }, [transcription]);
  useEffect(() => {
    const chunkedSegments = createChunkedSegments(
      transcription.TranscriptionText,
      chunkSize
    );
    setVisibleChunks(chunkedSegments.slice(0, itemsPerPage));
    setHasMore(chunkedSegments.length > itemsPerPage);
  }, [transcription.TranscriptionText, chunkSize]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.playbackRate = playbackRate;
    }
  }, [playbackRate]);

  useEffect(() => {
    const handleTimeUpdate = () => {
      if (audioRef.current) {
        const newCurrentTime = audioRef.current.currentTime;
        setCurrentTime(newCurrentTime);

        const newIndex = visibleChunks.findIndex(
          (chunk, index) =>
            newCurrentTime >= chunk.start &&
            (index === visibleChunks.length - 1 ||
              newCurrentTime < visibleChunks[index + 1].start)
        );

        if (newIndex !== -1 && newIndex !== currentSegmentIndex) {
          setCurrentSegmentIndex(newIndex);
        }
      }
    };

    audioRef.current?.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      audioRef.current?.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [visibleChunks, currentSegmentIndex]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value.trim();
    setSearchTerm(term);
    if (term === "") {
      setSearchResults([]);
      setCurrentSearchIndex(-1);
      return;
    }

    // Determine if we should use partial or exact matching
    const words = term.split(/\s+/);
    const useExactMatch = words.length > 1;

    // Create the appropriate regular expression
    const searchRegex = useExactMatch
      ? new RegExp(escapeRegExp(term), "gi")
      : new RegExp(
          words.map((word) => `(${escapeRegExp(word)})`).join("|"),
          "gi"
        );

    const results = transcription.TranscriptionText.reduce(
      (acc: { index: number; matches: number[] }[], segment, index) => {
        const matches: number[] = [];
        let match;
        let log_results: any = {};
        while ((match = searchRegex.exec(segment.text)) !== null) {
          matches.push(match.index);
          log_results = {
            segment: segment.text,
            index: index,
            matches: matches,
            match: match,
            segment_start: segment.start,
          };
        }

        if (matches.length > 0) {
          console.log("Search results for segment: ", log_results);
          acc.push({ index, matches });
        }
        return acc;
      },
      []
    );

    console.log("Search term:", term);
    console.log("Search regex:", searchRegex);
    console.log("Search results:", results);

    setSearchResults(results);
    setCurrentSearchIndex(results.length > 0 ? 0 : -1);
  };

  // Helper function to escape special characters in the search term
  const escapeRegExp = (string: string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };
  /**
   * Jumps to the specified search result index.
   *
   * @param resultIndex - The index of the search result to jump to.
   */
  const jumpToSearchResult = (resultIndex: number) => {
    if (
      searchResults.length > 0 &&
      resultIndex >= 0 &&
      resultIndex < searchResults.length
    ) {
      console.log(
        "jumpToSearchResult, resultIndex: ",
        resultIndex,
        "searchResults: ",
        searchResults,
        "searchResults[resultIndex]: ",
        searchResults[resultIndex]
      );
      setCurrentSearchIndex(resultIndex);

      const targetSegmentIndex = searchResults[resultIndex].index;
      const chunkIndex = Math.floor(targetSegmentIndex / chunkSize);
      const startIndex = chunkIndex * chunkSize;
      const endIndex = Math.min(
        (chunkIndex + 1) * chunkSize,
        transcription.TranscriptionText.length
      );

      setVisibleChunks(
        createChunkedSegments(
          transcription.TranscriptionText.slice(startIndex, endIndex),
          chunkSize
        )
      );

      const indexWithinChunk = targetSegmentIndex % chunkSize;
      setCurrentSegmentIndex(targetSegmentIndex); // Changed this line

      console.log(
        "jumpToSearchResult, targetSegmentIndex: ",
        targetSegmentIndex,
        "indexWithinChunk: ",
        indexWithinChunk
      );

      setTimeout(() => {
        scrollToSegment(indexWithinChunk);
      }, 100); // Small delay to ensure the DOM has updated
    }
  };

  const jumpToNextResult = () => {
    if (searchResults.length > 0) {
      const nextIndex = (currentSearchIndex + 1) % searchResults.length;
      jumpToSearchResult(nextIndex);
    }
  };

  const jumpToPreviousResult = () => {
    if (searchResults.length > 0) {
      const prevIndex =
        (currentSearchIndex - 1 + searchResults.length) % searchResults.length;
      jumpToSearchResult(prevIndex);
    }
  };

  const scrollToSegment = (index: number) => {
    console.log("scrollToSegment, index: ", index);
    const element = document.getElementById(`segment-${index}`);
    console.log("scrollToSegment, element: ", element);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const highlightText = (text: string, matches: number[]) => {
    if (matches.length === 0) return text;
    let result = [];
    let lastIndex = 0;

    const words = searchTerm.split(/\s+/);
    const useExactMatch = words.length > 1;

    const highlightRegex = useExactMatch
      ? new RegExp(escapeRegExp(searchTerm), "gi")
      : new RegExp(
          words.map((word) => `(${escapeRegExp(word)})`).join("|"),
          "gi"
        );

    matches.sort((a, b) => a - b);

    matches.forEach((matchIndex) => {
      result.push(text.slice(lastIndex, matchIndex));
      const remainingText = text.slice(matchIndex);
      const match = highlightRegex.exec(remainingText);
      if (match) {
        result.push(
          <span
            key={matchIndex}
            style={{ backgroundColor: "yellow", fontWeight: "bold" }}
          >
            {match[0]}
          </span>
        );
        lastIndex = matchIndex + match[0].length;
      }
    });
    result.push(text.slice(lastIndex));
    return result;
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (event.shiftKey) {
        jumpToPreviousResult();
      } else {
        jumpToNextResult();
      }
    }
  };

  const createChunkedSegments = (
    segments: TranscriptionSegment[],
    size: number
  ): ChunkedSegment[] => {
    const chunked: ChunkedSegment[] = [];
    for (let i = 0; i < segments.length; i += size) {
      const chunk = segments.slice(i, i + size);
      chunked.push({
        id: chunk[0].id,
        text: chunk.map((seg) => seg.text).join(" "),
        start: chunk[0].start,
        end: chunk[chunk.length - 1].end,
        originalSegments: chunk,
      });
    }
    return chunked;
  };

  const lastChunkRef = (node: HTMLLIElement) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        loadMoreChunks();
      }
    });
    if (node) observer.current.observe(node);
  };

  const loadMoreChunks = () => {
    const currentLength = visibleChunks.length;
    const allChunkedSegments = createChunkedSegments(
      transcription.TranscriptionText,
      chunkSize
    );
    const nextChunks = allChunkedSegments.slice(
      currentLength,
      currentLength + itemsPerPage
    );
    setVisibleChunks((prev) => [...prev, ...nextChunks]);
    setHasMore(currentLength + nextChunks.length < allChunkedSegments.length);
  };

  const togglePlayPause = (segment: TranscriptionSegment, index: number) => {
    console.log(
      "togglePlayPause, index: ",
      index,
      "currentSegmentIndex: ",
      currentSegmentIndex,
      segment.id
    );
    logCustomEvent("player_event", {
      label: segment.id,
      subject: transcription?.name,
      transcriptionName: transcription.filename ?? "",
      time: currentTime,
    });
    if (!audioRef.current) return;

    if (currentSegmentIndex !== index) {
      audioRef.current.currentTime = segment.start;
      setCurrentSegmentIndex(index);
      console.log("togglePlayPause, setting currentTime: ", segment.start);
    }

    if (audioRef.current.paused) {
      audioRef.current
        .play()
        .then(() => setIsPlaying(true))
        .catch((error) => console.error("Error playing audio:", error));
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleSpeedChange = (increment: boolean) => {
    setPlaybackRate((prevRate) => {
      const newRate = increment ? prevRate + 0.25 : prevRate - 0.25;
      return Math.max(0.5, Math.min(5, Number(newRate.toFixed(2))));
    });
  };

  const calculateProgress = (chunk: ChunkedSegment, index: number) => {
    if (index === currentSegmentIndex) {
      const chunkDuration = chunk.end - chunk.start;
      const chunkProgress = currentTime - chunk.start;
      console.log(
        `chunkProgress: ${chunkProgress}, chunkDuration: ${chunkDuration}`
      ); // Added for debugging
      return (chunkProgress / chunkDuration) * 100;
    }
    return index < currentSegmentIndex ? 100 : 0;
  };

  useEffect(() => {
    if (transcription.TranscriptionText.length > 0) {
      const lastSegment =
        transcription.TranscriptionText[
          transcription.TranscriptionText.length - 1
        ];
      setTotalDuration(lastSegment.end);
    }
  }, [transcription.TranscriptionText]);

  const handleChunkSizeChange = (event: Event, newValue: number | number[]) => {
    setChunkSize(newValue as number);
  };

  const formatTime = (seconds: number) => {
    const pad = (num: number) => num.toString().padStart(2, "0");
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
  };

  useEffect(() => {
    const handleGlobalKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter" && event.target !== searchInputRef.current) {
        event.preventDefault();
        if (event.shiftKey) {
          jumpToPreviousResult();
        } else {
          jumpToNextResult();
        }
      }
    };

    window.addEventListener("keydown", handleGlobalKeyPress);

    return () => {
      window.removeEventListener("keydown", handleGlobalKeyPress);
    };
  }, [jumpToNextResult, jumpToPreviousResult]);

  const renderListItems = () => {
    const chunkStartIndex =
      Math.floor(currentSegmentIndex / chunkSize) * chunkSize;

    return visibleChunks.map((segment, index) => {
      const isLastElement = index === visibleChunks.length - 1;

      const globalIndex = chunkStartIndex + index;
      const searchResult = searchResults.find(
        (result) => result.index === globalIndex
      );
      const isCurrentSearchResult =
        searchResults[currentSearchIndex]?.index === globalIndex;

      console.log(segment);
      if (isCurrentSearchResult) {
        console.log(
          "Current search result found:",
          "currentSegmentIndex:",
          currentSegmentIndex,
          "chunkSize:",
          chunkSize,

          "chunkStartIndex:",
          chunkStartIndex,
          "globalIndex:",
          globalIndex,
          "currentSearchIndex:",
          currentSearchIndex,
          "searchResult:",
          searchResults[currentSearchIndex]
        );
      }

      return (
        <ListItem
          ref={isLastElement ? lastChunkRef : null}
          id={`segment-${index}`}
          key={index}
          sx={{
            bgcolor: isCurrentSearchResult
              ? "lightyellow" // Changed from "lightyellow" to "yellow" for more visibility
              : index === currentSegmentIndex
              ? "lightgray"
              : "inherit",
            display: "flex",
            flexDirection: "row-reverse",
            direction: "rtl",
            textAlign: "right",
          }}
        >
          <ListItemText
            primary={
              <Typography variant="body1">
                {searchResult
                  ? highlightText(segment.text, searchResult.matches)
                  : segment.text}
              </Typography>
            }
            secondary={
              <>
                {`${formatTime(segment.start)} - ${formatTime(segment.end)}`}
                {index === currentSegmentIndex && isPlaying && (
                  <LinearProgress
                    variant="determinate"
                    value={calculateProgress(segment, index)}
                  />
                )}
              </>
            }
          />
          <IconButton
            onClick={() => togglePlayPause(segment, index)}
            size="small"
            sx={{
              marginLeft: "15px",
              backgroundColor: "rgb(66, 133, 244)",
              color: "white",
            }}
          >
            {(segment.originalSegments as any).some(
              (segment: any) => segment.path
            ) ? (
              <ImageIcon />
            ) : index === currentSegmentIndex && isPlaying ? (
              <PauseIcon />
            ) : (
              <PlayArrowIcon />
            )}
          </IconButton>
        </ListItem>
      );
    });
  };
  return (
    <Box
      sx={{
        maxWidth: "1200px",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor: "white",
          padding: 2,
          borderBottom: "1px solid #ddd",
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: { xs: "100%", sm: "40%" },
          }}
        >
          <Typography variant="subtitle2" sx={{ marginRight: 1 }}>
            גודל משפט:
          </Typography>
          <Slider
            size="small"
            value={chunkSize}
            onChange={handleChunkSizeChange}
            aria-labelledby="chunk-size-slider"
            valueLabelDisplay="auto"
            step={3}
            marks
            min={1}
            max={24}
          />
        </Box>
        <Box
          sx={{
            width: { xs: "100%", sm: "60%" },
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2" sx={{ fontWeight: "bold", mx: 1 }}>
              מהירות השמעה:
            </Typography>
            <IconButton onClick={() => handleSpeedChange(false)} size="small">
              <RemoveIcon />
            </IconButton>
            <Typography variant="body2" sx={{ mx: 1 }}>
              {playbackRate.toFixed(2)}x
            </Typography>
            <IconButton onClick={() => handleSpeedChange(true)} size="small">
              <AddIcon />
            </IconButton>
          </Box>
          <Typography
            variant="body2"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            משך הרצאה: {formatTime(totalDuration)}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <TextField
            fullWidth
            size="small"
            value={searchTerm}
            onChange={handleSearch}
            onKeyDown={handleKeyPress}
            placeholder="חיפוש בתמלול"
            inputRef={searchInputRef}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Typography variant="body2">
            {searchResults.length > 0
              ? `${currentSearchIndex + 1}/${searchResults.length}`
              : "0/0"}
          </Typography>
          <Tooltip title="Previous (Shift+Enter)">
            <span>
              <IconButton
                onClick={jumpToPreviousResult}
                disabled={searchResults.length === 0}
              >
                <Typography>↑</Typography>
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Next (Enter)">
            <span>
              <IconButton
                onClick={jumpToNextResult}
                disabled={searchResults.length === 0}
              >
                <Typography>↓</Typography>
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      </Box>

      <Box
        ref={listRef}
        style={{
          height: "80vh",
          overflowY: "auto",
          scrollbarWidth: "none" /* For Firefox */,
          msOverflowStyle: "none" /* For Internet Explorer and Edge */,
        }}
      >
        <style>
          {`
        /* For WebKit browsers */
        div::-webkit-scrollbar {
          display: none;
        }
      `}
        </style>

        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {renderListItems()}
        </List>

        <audio ref={audioRef} src={transcription.AudioSrc} />
      </Box>
    </Box>
  );
};

export default TranscriptionPlayer;
