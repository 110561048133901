import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Stack,
  Modal,
} from "@mui/material";
import { Transcription, Term, SummeryComment } from "../Constants/interface";
import { TranscriptMetadata } from "./Services/fetchService";
import { logCustomEvent, logEvent } from "../utils/Analytics";
import { createStyles } from "./styles";
import { styled } from "@mui/material/styles";

// Component Imports
import TranscriptionPlayer from "./TranscriptionPlayer";
import Comments from "./Comments";
import Markdown from "./Markdown";
import ContentControls from "./ContentControls";
import { ReactToPDFComponent } from "./Tmp_savetopdf";
import { TOCItem } from "./TableOfContents";
import TableOfContents from "./TableOfContents";

// Constants
const frameBaseUrl = "https://transcriptionsdata.blob.core.windows.net/";

// Types
interface ContentDisplayProps {
  view: "transcription" | "summary";
  setView: React.Dispatch<React.SetStateAction<"transcription" | "summary">>;
  length: "short" | "long";
  setLength: React.Dispatch<React.SetStateAction<"short" | "long">>;
  transcription: Transcription | null;
  playbackRate: number;
  SummeryComment?: SummeryComment[];
  metadata: TranscriptMetadata;
}

const ContentContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  maxWidth: "1000px",
  margin: "0 auto",
  padding: theme.spacing(2),
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  paddingTop: "80px",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    paddingTop: "40px",
  },
}));

const ContentDisplay: React.FC<ContentDisplayProps> = ({
  view,
  setView,
  length,
  setLength,
  transcription,
  playbackRate,
  SummeryComment,
  metadata,
}) => {
  // State Management
  const [comments, setComments] = useState<SummeryComment[] | undefined>(
    SummeryComment
  );
  const [showImages, setShowImages] = useState(true);
  const [searchSummeryTerm, setSearchSummeryTerm] = useState("");
  const [fontSize, setFontSize] = useState(1);
  const [enlargedImage, setEnlargedImage] = useState<string | null>(null);
  const [tocState, setTocState] = useState<{
    items: TOCItem[];
    activeId: string;
    collapsedHeaders: Set<string>;
    toggleCollapse: (headerId: string) => void;
  }>({
    items: [],
    activeId: "",
    collapsedHeaders: new Set(),
    toggleCollapse: () => {},
  });
  const [isTocOpen, setIsTocOpen] = useState(true);

  const filteredTerms = transcription?.Terms?.filter((term) =>
    term.term.toLowerCase().includes(searchSummeryTerm.toLowerCase())
  );

  // Event Handlers
  const handleImageClick = (src: string) => {
    const formattedSrc = src.replace("\\\\", "/");
    logEvent("image_enlarged", {
      imageSrc: formattedSrc,
      transcriptionName: transcription?.name,
    });
    setEnlargedImage(formattedSrc);
  };

  const handleFontSizeChange = (increase: boolean) => {
    const newSize = increase
      ? Math.min(fontSize + 0.1, 3)
      : Math.max(fontSize - 0.1, 0.1);

    setFontSize(newSize);
    logEvent(`${increase ? "increase" : "decrease"}_font_size`, {
      fontSize: newSize,
      transcriptionName: transcription?.name,
    });
  };

  const handleSearchTerms = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    setSearchSummeryTerm(searchTerm);
    if (searchTerm.length > 5) {
      logCustomEvent("search_terms", {
        searchTerm,
        transcriptionName: transcription?.name,
      });
    }
  };

  const handleTocChange = useCallback((newTocState: typeof tocState) => {
    setTocState(newTocState);
  }, []);
  console.log("transcription", transcription);
  // Render Methods
  const renderTerms = (factor: number) => {
    if (!filteredTerms?.length) return <Typography>No terms found.</Typography>;

    const styles = createStyles(factor, length === "long");
    return filteredTerms.map((term: Term, index: number) => (
      <Box key={index} mb={2} sx={styles.markdownContainer}>
        <Box sx={styles.markdownTermTextTitle}>{term.term}</Box>
        <Box sx={styles.markdownTermText}>{term.definition}</Box>
        <Box sx={styles.markdownTermText}>
          <strong>מונחים קשורים</strong>: {term.relatedTerms.join(", ")}
        </Box>
        <Box sx={styles.markdownTermText}>
          <strong>הופעות</strong>: {term.appearances.join(", ")}
        </Box>
      </Box>
    ));
  };

  const renderContent = (
    factor: number,
    termCount: number,
    toPDF: boolean = false
  ) => {
    console.log("renderContent", view);
    if (view === "transcription") {
      return (
        transcription && (
          <TranscriptionPlayer
            playbackRate={playbackRate}
            transcription={transcription}
          />
        )
      );
    }

    if (!transcription) return null;

    const styles = createStyles(factor, length === "long");

    if (
      length !== "long" &&
      transcription.Terms?.length &&
      transcription.Terms.length > 0
    ) {
      return (
        <Box sx={styles.markdownContainer}>
          <Box sx={styles.markdownTermTextTitle}>
            מונחים מופעים בסיכום ({termCount} מונחים)
          </Box>
          {renderTerms(factor)}
        </Box>
      );
    }

    if (transcription.TranscriptionSummary) {
      return (
        <Markdown
          toPDF={toPDF}
          handleImageClick={handleImageClick}
          fontSize={fontSize}
          isLongForm={length === "long"}
          showImages={showImages}
          isTocOpen={isTocOpen}
          onTocItemsChange={handleTocChange}
        >
          {length === "long"
            ? transcription.TranscriptionSummary
            : transcription.ShortTranscriptionSummary ?? ""}
        </Markdown>
      );
    }

    return <Typography>עדיין אין סיכום ...</Typography>;
  };

  // Effects
  useEffect(() => {
    logCustomEvent("content_display_loaded", {
      view,
      transcriptionName: transcription?.name,
      length,
      playbackRate,
    });
  }, [view, transcription, length, playbackRate]);
  return (
    <>
      {view !== "transcription" ? (
        <Box sx={{ direction: "rtl" }}>
          <ContentControls
            isTocOpen={isTocOpen}
            onToggleToc={() => setIsTocOpen((prev) => !prev)}
            view={view}
            setView={setView}
            length={length}
            setLength={setLength}
            fontSize={fontSize}
            showImages={showImages}
            onIncreaseFontSize={() => handleFontSizeChange(true)}
            onDecreaseFontSize={() => handleFontSizeChange(false)}
            onToggleImages={() => setShowImages((prev) => !prev)}
            isMobile={window.innerWidth < 768}
          />

          <ContentWrapper>
            <TableOfContents
              items={tocState.items}
              activeId={tocState.activeId}
              collapsedHeaders={tocState.collapsedHeaders}
              toggleCollapse={tocState.toggleCollapse}
              isOpen={isTocOpen}
              onClose={() => setIsTocOpen(false)}
            />
            <ContentContainer>
              <Comments
                comments={comments ?? []}
                lectureId={transcription?.id?.toString() ?? "unknown"}
                setComments={
                  setComments as React.Dispatch<
                    React.SetStateAction<SummeryComment[]>
                  >
                }
                lectureMetadata={metadata}
              />

              <Card
                sx={{
                  padding: { xs: 0.5, sm: 2, md: 3 },
                  maxWidth: "1200px",
                }}
              >
                <CardContent sx={{ direction: "rtl", textAlign: "right" }}>
                  {length !== "long" &&
                    transcription?.Terms?.length &&
                    transcription.Terms.length > 0 && (
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Search Terms"
                        value={searchSummeryTerm}
                        onChange={handleSearchTerms}
                        sx={{ mb: 2 }}
                      />
                    )}

                  <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                    <ReactToPDFComponent
                      transcriptionName={transcription?.name ?? "unknown"}
                      content={
                        <div style={{ direction: "rtl", textAlign: "right" }}>
                          {renderContent(
                            fontSize,
                            filteredTerms?.length ?? 0,
                            false
                          )}
                        </div>
                      }
                    />
                  </Stack>

                  <div id="pdf-content">
                    <Markdown
                      handleImageClick={handleImageClick}
                      fontSize={fontSize}
                      isLongForm={length === "long"}
                      showImages={showImages}
                      isTocOpen={isTocOpen}
                      onTocItemsChange={handleTocChange}
                    >
                      {length === "long"
                        ? transcription?.TranscriptionSummary
                        : transcription?.ShortTranscriptionSummary ?? ""}
                    </Markdown>
                  </div>
                </CardContent>
              </Card>
            </ContentContainer>

            {enlargedImage && (
              <Modal open={true} onClose={() => setEnlargedImage(null)}>
                <Box
                  sx={{
                    boxShadow: 24,
                    maxWidth: { xs: "90vw", md: "70vw" },
                    alignItems: "center",
                  }}
                >
                  <img
                    src={enlargedImage}
                    alt="Enlarged"
                    style={{ width: "100%", alignItems: "center" }}
                  />
                </Box>
              </Modal>
            )}
          </ContentWrapper>
        </Box>
      ) : (
        <Box sx={{ direction: "rtl" }}>
          <ContentControls
            isTocOpen={isTocOpen}
            onToggleToc={() => setIsTocOpen((prev) => !prev)}
            view={view}
            setView={setView}
            length={length}
            setLength={setLength}
            fontSize={fontSize}
            showImages={showImages}
            onIncreaseFontSize={() => handleFontSizeChange(true)}
            onDecreaseFontSize={() => handleFontSizeChange(false)}
            onToggleImages={() => setShowImages((prev) => !prev)}
            isMobile={window.innerWidth < 768}
          />
          <ContentWrapper>
            <ContentContainer>
              <TranscriptionPlayer
                playbackRate={playbackRate}
                transcription={transcription ?? ({} as Transcription)}
              />
            </ContentContainer>
          </ContentWrapper>
        </Box>
      )}
    </>
  );
};

export default ContentDisplay;
