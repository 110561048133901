import React, { useEffect, useState } from "react";
import { SummeryComment, Transcription } from "../Constants/interface";
import {
  TranscriptMetadata,
  fetchCommentsFromDatabase,
  getAllBlobNames,
  getTranscriptMetadataDirectly,
} from "./Services/fetchService";
import TranscriptionLayout from "./TranscriptionLayout";
import useWindowSize from "./useWindowSize";
import { Box, CircularProgress, Typography } from "@mui/material";
import { logCustomEvent, logEvent, logPageView } from "../utils/Analytics";
import ResponsiveAppBar from "../shared/AppBar";

// Update the gradient function for a stronger, more dynamic look
const getBackgroundGradient = () => {
  // Rich, professional gradient that suggests depth and intelligence
  return "linear-gradient(135deg, #0A2F5C 0%, #185A9D 50%, #43CEA2 100%)";
};

export const TranscriptionMain: React.FC = () => {
  const [isTestMode, setIsTestMode] = useState<boolean>(false);
  const [transcription, setTranscription] = useState<Transcription | null>(
    null
  );
  const [view, setView] = useState<"transcription" | "summary">("summary");
  const [length, setLength] = useState<"short" | "long">("long");
  const [transcriptionMetadata, setTranscriptionMetadata] = useState<
    TranscriptMetadata[]
  >([]);
  const [isMetadataLoading, setIsMetadataLoading] = useState<boolean>(true);

  const { width } = useWindowSize();
  const isMobile = width ? width <= 600 : false;
  const [playbackRate] = useState(1);
  const [comments, setComments] = useState<SummeryComment[] | undefined>();
  const [metadata, setMetadata] = useState<
    TranscriptMetadata | undefined | string
  >();
  const [isIsolatedLink, setIsIsolatedLink] = useState<boolean>(false);

  useEffect(() => {
    logEvent("component_view", {
      category: "User Navigation",
      action: "Viewed Component",
      label: "TranscriptionMain",
    });

    logCustomEvent("session_start", {
      timestamp: new Date().toISOString(),
    });

    logPageView();
    logEvent("transcription_main_loaded", {});

    return () => {
      logCustomEvent("session_end", {
        timestamp: new Date().toISOString(),
      });
    };
  }, []);

  useEffect(() => {
    if (transcription) {
      logCustomEvent("transcription_selected", {
        category: "TranscriptionMain",
        action: "Transcription Selected",
        subject: transcription.name ?? "",
        transcriptionName: transcription.filename ?? "",
        view: view,
        length: length,
      });
    }
  }, [transcription, view, length]);

  useEffect(() => {
    logCustomEvent("selected_summery", {
      subject: transcription?.name,
      name: transcription?.filename,
    });
  }, [transcription]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const testParam = urlParams.get("test");
    const sectionParam = urlParams.get("section");
    const isTesting = testParam === "true";
    if (sectionParam == "legal_metadata.json") {
      setIsIsolatedLink(true);
    }
    setIsTestMode(isTesting);

    const fetchData = async () => {
      setIsMetadataLoading(true);
      try {
        const data = isTesting
          ? await getAllBlobNames()
          : await getTranscriptMetadataDirectly(
              sectionParam ?? "metadata.json"
            );

        setTranscriptionMetadata(data);
      } catch (error) {
        console.error("Error fetching metadata:", error);
      } finally {
        setIsMetadataLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchComments = async () => {
      if (metadata) {
        const comments = await fetchCommentsFromDatabase();
        setComments(comments);
      }
    };
    fetchComments();
  }, [metadata]);

  return (
    <Box
      sx={{
        maxWidth: "100vw",
        minHeight: "100vh",
        backgroundImage: `
          linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 50%, rgba(255, 255, 255, 0) 100%),
          linear-gradient(90deg, rgba(255, 255, 255, 0.02) 1px, transparent 1px),
          linear-gradient(0deg, rgba(255, 255, 255, 0.02) 1px, transparent 1px)
        `,
        animation: "slideDown 2s linear infinite",
      }}
    >
      <Box
        sx={{
          background: getBackgroundGradient(),
          width: "100%",
          minHeight: "100vh",
          "&::before": {
            content: '""',
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            pointerEvents: "none",
            zIndex: 1,
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {!isIsolatedLink && <ResponsiveAppBar opacity={0.35} />}
        {isMetadataLoading ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100vh"
          >
            <Box
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                backdropFilter: "blur(10px)",
                p: 2,
                flexDirection: "column",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 4,
              }}
            >
              <CircularProgress
                size={60}
                sx={{ color: "rgba(255, 255, 255, 0.2)" }}
              />
              <Typography
                variant="h6"
                sx={{
                  direction: "rtl",
                  marginTop: 2,
                  color: "rgba(255, 255, 255, 0.2)",
                }}
              >
                טוען הרצאות ...
              </Typography>
            </Box>
          </Box>
        ) : (
          <TranscriptionLayout
            isTestMode={isTestMode}
            transcription={transcription}
            setTranscription={(newTranscription) => {
              setTranscription(newTranscription);
            }}
            view={view}
            setView={(newView) => {
              logEvent("view_change", {
                category: "User Interaction",
                action: "Changed View",
                label: newView,
              });
              setView(newView);
            }}
            length={length}
            setLength={(newLength) => {
              logEvent("length_change", {
                category: "User Interaction",
                action: "Changed Length",
                label: newLength,
              });
              setLength(newLength);
            }}
            transcriptionMetadata={transcriptionMetadata}
            isMobile={isMobile}
            playbackRate={playbackRate}
            comments={comments}
            setMetadata={setMetadata}
            metadata={metadata}
          />
        )}
      </Box>
    </Box>
  );
};

export default TranscriptionMain;
