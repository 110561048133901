import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  CircularProgress,
  Grid,
  Card,
} from "@mui/material";
import { SummeryComment, Transcription } from "../Constants/interface";
import { TranscriptMetadata } from "./Services/fetchService";
import SelectLecture from "./TreeSelectedLecture";
import ToggleButtons from "./ToggleButtons";
import { StyledPaper, TranscriptionContentPaper } from "./StyledPaper";
import ContentDisplay from "./ContentDisplay";
import { SIZES } from "./ControlStyles";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";

interface TranscriptionLayoutProps {
  playbackRate: number;
  isTestMode: boolean;
  transcription: Transcription | null;
  setTranscription: React.Dispatch<React.SetStateAction<Transcription | null>>;
  view: "transcription" | "summary";
  setView: React.Dispatch<React.SetStateAction<"transcription" | "summary">>;
  length: "short" | "long";
  setLength: React.Dispatch<React.SetStateAction<"short" | "long">>;
  transcriptionMetadata: TranscriptMetadata[];
  isMobile: boolean;
  comments: SummeryComment[] | undefined;
  setMetadata: React.Dispatch<
    React.SetStateAction<TranscriptMetadata | string | undefined>
  >;
  metadata: TranscriptMetadata | undefined | string;
}

const LayoutWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  maxWidth: "1400px",
  [theme.breakpoints.down("sm")]: {
    width: "95vw",
  },
}));

const TranscriptionLayout: React.FC<TranscriptionLayoutProps> = ({
  isTestMode,
  playbackRate,
  transcription,
  setTranscription,
  view,
  setView,
  length,
  setLength,
  transcriptionMetadata,
  isMobile,
  comments,
  setMetadata,
  metadata,
}) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);

  const updateTranscription = async (newTranscription: Transcription) => {
    setIsLoading(true);
    try {
      setTranscription(newTranscription);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LayoutWrapper>
      <Box sx={{ flex: 1 }}>
        <StyledPaper>
          <Typography
            variant="h5"
            component="h1"
            gutterBottom
            align="center"
            sx={{
              color: "rgba(255, 255, 255, 0.95)",
              fontWeight: 600,
              letterSpacing: "0.5px",
              textShadow: "0 2px 4px rgba(0,0,0,0.2)",
              position: "relative",
            }}
          >
            בחר סיכום
          </Typography>

          {/* Loading State */}
          {isLoading && (
            <Box
              sx={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 9999,
                background: "rgba(10, 47, 92, 0.8)",
                backdropFilter: "blur(12px)",
              }}
            >
              <Card
                sx={{
                  background: "rgba(255, 255, 255, 0.1)",
                  backdropFilter: "blur(16px)",
                  padding: 4,
                  borderRadius: "20px",
                  border: "1px solid rgba(255, 255, 255, 0.15)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 2,
                  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.2)",
                }}
              >
                <CircularProgress
                  size={80}
                  thickness={4}
                  sx={{
                    color: "rgba(255, 255, 255, 0.3)",
                    filter: "drop-shadow(0 0 10px rgba(255,255,255,0.3))",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    color: "rgba(255, 255, 255, 0.3)",
                    textShadow: "0 2px 4px rgba(0,0,0,0.2)",
                  }}
                >
                  טוען את הסיכום...
                </Typography>
              </Card>
            </Box>
          )}

          <SelectLecture
            isTestMode={isTestMode}
            setIsLoading={setIsLoading}
            metadata={transcriptionMetadata}
            setTranscription={setTranscription}
            setMetadata={setMetadata}
          />
        </StyledPaper>

        {transcription && !isLoading && (
          <TranscriptionContentPaper>
            <ContentDisplay
              metadata={metadata as TranscriptMetadata}
              playbackRate={playbackRate}
              view={view}
              transcription={transcription}
              length={length}
              SummeryComment={comments}
              setView={setView}
              setLength={setLength}
            />
          </TranscriptionContentPaper>
        )}
      </Box>
    </LayoutWrapper>
  );
};

export default TranscriptionLayout;
