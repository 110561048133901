import React, { useState } from "react";
import { Transcription } from "../Constants/interface";
import {
  TranscriptMetadata,
  getTranscript,
  getTranscriptDirectly,
} from "./Services/fetchService";
import { Typography, Paper, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { logCustomEvent } from "../utils/Analytics";

interface SelectLectureProps {
  metadata: TranscriptMetadata[] | string[];
  setTranscription: (transcription: Transcription) => void;
  setMetadata: React.Dispatch<
    React.SetStateAction<TranscriptMetadata | string | undefined>
  >;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isTestMode: boolean;
}
const commonTypos: Record<string, string> = {
  "משפט משפט": "משפט",
};
const secondYearSubjects = ["אמבריולוגיה", "פיזיולוגיה", "יום קליני"];
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(2),
  background: "rgba(255, 255, 255, 0.09)",
  borderRadius: 20,
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.15)",
  backdropFilter: "blur(10px)",
  border: "1px solid rgba(255, 255, 255, 0.15)",
  direction: "rtl",
  transition: "all 0.3s ease",
  "&:hover": {
    boxShadow: "0 12px 40px rgba(0, 0, 0, 0.2)",
  },
}));

export const SelectLecture = ({
  metadata,
  setIsLoading,
  setTranscription,
  setMetadata,
  isTestMode,
}: SelectLectureProps) => {
  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  const handleSelectedItemsChange = async (
    event: React.SyntheticEvent,
    itemIds: string | null
  ) => {
    setSelectedItem(itemIds);
    if (itemIds) {
      try {
        setIsLoading(true);
        let newTranscription: Transcription;
        let selectedLecture: TranscriptMetadata | string | undefined;

        if (isTestMode) {
          selectedLecture = itemIds;
          selectedLecture = selectedLecture.split(".")[0];
          newTranscription = await getTranscript(selectedLecture);
        } else {
          selectedLecture = (metadata as TranscriptMetadata[]).find(
            (lecture) => lecture.name === itemIds
          );
          if (!selectedLecture) {
            throw new Error("Selected lecture not found in metadata");
          }
          newTranscription = await getTranscript(selectedLecture.filename);
          logCustomEvent("lecture_selected", {
            lecture: selectedLecture.name ?? selectedLecture,
            subject: selectedLecture.subject ?? selectedLecture,
          });
        }

        setTranscription(newTranscription);
        setMetadata(selectedLecture);
        console.log("Transcription fetched:", selectedLecture);
      } catch (error) {
        console.error("Error fetching transcript:", error);
      } finally {
        setIsLoading(false);
      }
    } else {
      console.log("No item selected");
    }
  };
  const getColor = (depth: number): string => {
    switch (depth) {
      case 0:
        return "rgba(255, 255, 255, 1)";
      case 1:
        return "rgba(255, 255, 255, 0.85)";
      default:
        return "rgba(255, 255, 255, 0.8)";
    }
  };

  const renderTreeItems = () => {
    if (isTestMode) {
      return (metadata as string[]).map((filename, index) => (
        <TreeItem
          key={`${filename}-${index}`}
          itemId={filename}
          label={filename}
        />
      ));
    } else {
      const groupedByYear = (metadata as TranscriptMetadata[]).reduce(
        (acc, lecture) => {
          if (lecture && lecture.subject && lecture.name) {
            const year = secondYearSubjects.includes(lecture.subject)
              ? "שנה ב"
              : "שנה א";

            if (!acc[year]) {
              acc[year] = {};
            }
            if (!acc[year][lecture.subject]) {
              acc[year][lecture.subject] = [];
            }
            Object.keys(commonTypos).forEach((typo) => {
              if (lecture.name.includes(typo)) {
                lecture.name = lecture.name.replace(typo, commonTypos[typo]);
              }
            });
            acc[year][lecture.subject].push(lecture);
          }
          return acc;
        },
        {} as { [year: string]: { [subject: string]: TranscriptMetadata[] } }
      );

      Object.keys(groupedByYear).forEach((year) => {
        Object.keys(groupedByYear[year]).forEach((subject) => {
          groupedByYear[year][subject].sort((a, b) =>
            a.name.localeCompare(b.name, undefined, {
              numeric: true,
              sensitivity: "base",
            })
          );
        });
      });

      return Object.entries(groupedByYear).map(([year, subjects]) => (
        <TreeItem
          key={year}
          itemId={year}
          label={year}
          sx={{
            color: getColor(0),
            marginRight: 1,
          }}
        >
          {Object.entries(subjects).map(([subject, lectures]) => (
            <TreeItem
              key={`${year}-${subject}`}
              itemId={subject}
              label={subject}
              sx={{
                color: getColor(1),
                marginRight: 1.4,
              }}
            >
              {lectures.map((lecture) => (
                <TreeItem
                  key={lecture.name}
                  itemId={lecture.name}
                  label={lecture.name}
                  sx={{
                    color: getColor(2),
                    marginRight: 2.1,
                  }}
                />
              ))}
            </TreeItem>
          ))}
        </TreeItem>
      ));
    }
  };

  return (
    <StyledPaper elevation={3}>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          color: "rgba(255, 255, 255, 0.95)",
          fontWeight: 500,
          letterSpacing: "0.5px",
          textShadow: "0 2px 4px rgba(0,0,0,0.2)",
          borderBottom: "2px solid rgba(255, 255, 255, 0.1)",
          paddingBottom: 1,
          marginBottom: 2,
        }}
      >
        בחר קורס והרצאה
      </Typography>
      <Box
        sx={{
          minHeight: 200,
          maxHeight: 400,
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: "rgba(255, 255, 255, 0.05)",
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "rgba(255, 255, 255, 0.2)",
            borderRadius: "10px",
            "&:hover": {
              background: "rgba(255, 255, 255, 0.3)",
            },
          },
        }}
      >
        <SimpleTreeView
          selectedItems={selectedItem}
          onSelectedItemsChange={handleSelectedItemsChange}
          multiSelect={false}
          slots={{
            expandIcon: ChevronRightIcon,
            collapseIcon: ExpandMoreIcon,
          }}
          sx={{
            color: "rgba(255, 255, 255, 0.9)",
            "& .MuiTreeItem-content": {
              padding: "4px 8px",
              borderRadius: "8px",
              transition: "all 0.2s ease",
              "&:hover": {
                background: "rgba(255, 255, 255, 0.1)",
              },
              "&.Mui-selected": {
                background: "rgba(255, 255, 255, 0.15) !important",
              },
            },
            "& .MuiTreeItem-label": {
              fontSize: "0.95rem",
              fontWeight: 400,
            },
            "& .MuiTreeItem-group": {
              marginLeft: "17px",
              paddingLeft: "18px",
              borderLeft: "1px dashed rgba(255, 255, 255, 0.2)",
            },
          }}
        >
          {renderTreeItems()}
        </SimpleTreeView>
      </Box>
    </StyledPaper>
  );
};

export default SelectLecture;
