import React, { useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useReactToPrint } from "react-to-print";
import { Button } from "@mui/material";
import { logEvent } from "../utils/Analytics";

interface ReactToPDFProps {
  content: React.ReactNode;
  transcriptionName: string;
}

const ReactToPDFComponent: React.FC<ReactToPDFProps> = ({
  content,
  transcriptionName,
}) => {
  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <div style={{ display: "none" }}>
        <div ref={componentRef}>{content}</div>
      </div>
      <Button
        variant="contained"
        onClick={() => {
          logEvent("download_pdf", {
            transcriptionName: transcriptionName,
          });
          handlePrint();
        }}
      >
        הורד את הסיכום כ-PDF
      </Button>
    </>
  );
};

interface JSPDFHTML2CanvasProps {
  contentId: string;
}

const JSPDFHTML2CanvasComponent: React.FC<JSPDFHTML2CanvasProps> = ({
  contentId,
}) => {
  const generatePDF = async () => {
    const element = document.getElementById(contentId);
    if (!element) {
      console.error(`Element with id ${contentId} not found`);
      return;
    }

    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("content.pdf");
  };

  return (
    <button onClick={generatePDF}>Generate PDF (jsPDF + html2canvas)</button>
  );
};

export { ReactToPDFComponent, JSPDFHTML2CanvasComponent };
