import React from "react";
import {
  Tooltip,
  IconButton,
  Typography,
  Switch,
  useMediaQuery,
} from "@mui/material";
import LyricsOutlinedIcon from "@mui/icons-material/LyricsOutlined";
import ShortTextIcon from "@mui/icons-material/ShortText";
import SubjectIcon from "@mui/icons-material/Subject";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ImageIcon from "@mui/icons-material/Image";
import ToggleButton from "@mui/material/ToggleButton";
import {
  ControlContainer,
  ControlGroup,
  StyledToggleButtonGroup,
} from "./ControlStyles";
import MenuBookIcon from "@mui/icons-material/MenuBook";

interface ContentControlsProps {
  view: "transcription" | "summary";
  setView: (view: "transcription" | "summary") => void;
  length: "short" | "long";
  setLength: (length: "short" | "long") => void;
  fontSize: number;
  showImages: boolean;
  onIncreaseFontSize: () => void;
  onDecreaseFontSize: () => void;
  onToggleImages: () => void;
  isMobile: boolean;
  isTocOpen: boolean;
  onToggleToc: () => void;
}

export const ContentControls: React.FC<ContentControlsProps> = ({
  view,
  setView,
  length,
  setLength,
  fontSize,
  showImages,
  onIncreaseFontSize,
  onDecreaseFontSize,
  onToggleImages,
  isMobile,
  isTocOpen,
  onToggleToc,
}) => {
  const [toggleValue, setToggleValue] = React.useState(view);
  const handleToggle = (event: React.MouseEvent<HTMLElement>, value: any) => {
    console.log(value);
    setToggleValue(value);
    if (["short_summary", "summary"].includes(value)) {
      setView("summary");
      if (value === "short_summary") {
        setLength("short");
      } else {
        setLength("long");
      }
    } else {
      setView("transcription");
    }
  };

  return (
    <ControlContainer>
      <ControlGroup>
        <StyledToggleButtonGroup
          value={toggleValue}
          exclusive
          onChange={(_, newView) => handleToggle(_, newView)}
          size="large"
        >
          <Tooltip title="צפה בתמלול מלא" arrow>
            <ToggleButton value="transcription" sx={{ pr: 2 }}>
              תמלול
              {!isMobile && <LyricsOutlinedIcon sx={{ pr: 1 }} />}
            </ToggleButton>
          </Tooltip>
          <Tooltip title="צפה בסיכום" arrow>
            <ToggleButton value="summary" sx={{ pr: 2 }}>
              סיכום
              {!isMobile && <SubjectIcon sx={{ pr: 1 }} />}
            </ToggleButton>
          </Tooltip>
          <Tooltip title="הצג מונחים עיקריים" arrow>
            <ToggleButton value="short_summary" sx={{ pr: 2 }}>
              מונחים
              {!isMobile && <ShortTextIcon sx={{ pr: 1 }} />}
            </ToggleButton>
          </Tooltip>
        </StyledToggleButtonGroup>
      </ControlGroup>

      <ControlGroup>
        <Tooltip title="הקטן גודל טקסט" arrow>
          <IconButton
            onClick={onDecreaseFontSize}
            sx={{
              color: "rgba(255, 255, 255, 0.9)",
              "&:hover": { background: "rgba(255, 255, 255, 0.1)" },
            }}
          >
            <RemoveIcon />
          </IconButton>
        </Tooltip>
        <Typography
          sx={{
            color: "rgba(255, 255, 255, 0.9)",
            minWidth: { xs: 32, sm: 40 },
            fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" },
            textAlign: "center",
            fontWeight: 500,
          }}
        >
          {(fontSize * 100).toFixed(0)}%
        </Typography>
        <Tooltip title="הגדל גודל טקסט" arrow>
          <IconButton
            onClick={onIncreaseFontSize}
            sx={{
              color: "rgba(255, 255, 255, 0.9)",
              "&:hover": { background: "rgba(255, 255, 255, 0.1)" },
            }}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </ControlGroup>

      <ControlGroup>
        <Tooltip title={`${showImages ? "הסתר" : "הצג"} תמונות`} arrow>
          <IconButton
            onClick={onToggleImages}
            sx={{
              color: showImages
                ? "rgba(255, 255, 255, 0.9)"
                : "rgba(255, 255, 255, 0.5)",
              "&:hover": { color: "rgba(255, 255, 255, 0.9)" },
            }}
          >
            <ImageIcon />
          </IconButton>
        </Tooltip>
      </ControlGroup>

      {!isMobile && (
        <ControlGroup>
          <StyledToggleButtonGroup
            value={isTocOpen ? "open" : "closed"}
            exclusive
            onChange={() => onToggleToc()}
            size="large"
          >
            <Tooltip title={`${isTocOpen ? "הסתר" : "הצג"} תוכן עניינים`} arrow>
              <ToggleButton value="open">
                <MenuBookIcon />
              </ToggleButton>
            </Tooltip>
          </StyledToggleButtonGroup>
        </ControlGroup>
      )}
    </ControlContainer>
  );
};

export default ContentControls;
