import React from "react";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  LinearProgress,
  Skeleton,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import SmartToyTwoToneIcon from "@mui/icons-material/SmartToyTwoTone";
import ConversationHistory from "./ConversationHistory";
import { QueryInput } from "./QueryInput";
import { RatingComponent } from "../RatingComponent";
import { componentStyles } from "../styles";

interface ChatBoxProps {
  useGroq: boolean;
  loading: boolean;
  progress: number;
  active: boolean;
  partialResponse: string;
  timer: number;
  conversationHistory: any[];
  cardRTL: boolean;
  memeIndex: number;
  alignment: string;
  query: string;
  sessionId: string;
  handleFeedback: (index: number, isPositive: boolean) => void;
  handleAligmentChange: (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => void;
  handleShare: () => void;
  handleClickOpen: () => void;
  setTimer: (value: React.SetStateAction<number>) => void;
  onQueryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onQuerySubmit: () => void;
  toggleDrawer: (
    open: boolean
  ) => (
    event: React.KeyboardEvent | React.MouseEvent | React.TouchEvent
  ) => void;
  addToDrawer: () => void;
}

export const ChatBox: React.FC<ChatBoxProps> = ({
  useGroq,
  loading,
  progress,
  active,
  partialResponse,
  timer,
  conversationHistory,
  cardRTL,
  memeIndex,
  alignment,
  query,
  sessionId,
  handleFeedback,
  handleAligmentChange,
  handleShare,
  handleClickOpen,
  setTimer,
  onQueryChange,
  onQuerySubmit,
  toggleDrawer,
  addToDrawer,
}) => {
  const styles = componentStyles;

  return (
    <Box sx={styles.conversationBox}>
      <ConversationHistory
        onFeedback={handleFeedback}
        cardRTL={cardRTL}
        conversationHistory={conversationHistory}
      />

      {!useGroq && loading && progress < 99 ? (
        <Skeleton
          animation="pulse"
          variant="rectangular"
          sx={styles.skeletonResponseCard}
        />
      ) : (
        active && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Card sx={styles.partialResponseCard}>
              <Box position="relative" display="flex" flexDirection="column">
                <Box position="absolute" top={0} left={0} p={0}>
                  <SmartToyTwoToneIcon />
                </Box>
                <CardContent>
                  <ReactMarkdown
                    remarkPlugins={[remarkMath]}
                    rehypePlugins={[rehypeKatex]}
                  >
                    {partialResponse}
                  </ReactMarkdown>
                </CardContent>
              </Box>
            </Card>
            <CircularProgress color="warning" />
            <Box sx={styles.thinkingText}>{`מייצר תשובה... `}</Box>
          </Box>
        )
      )}

      {!useGroq && loading && (
        <Box sx={styles.loadingBox}>
          <Box sx={styles.linearProgressBox}>
            {progress < 99 && (
              <LinearProgress
                color="secondary"
                variant="determinate"
                value={progress}
              />
            )}
          </Box>
          <Box sx={styles.thinkingText}>
            המערכת חושבת ומחשבת... {timer.toFixed(1) + " שניות"}
          </Box>
          <Box sx={styles.animatedThinkingText}>
            זמן המתנה ממוצע לתחילת התשובה: 25 שניות, אם עדיין לא הופיעה תשובה
            אחרי 180 שניות משהו התקלקל
          </Box>
        </Box>
      )}

      <QueryInput
        memeIndex={memeIndex}
        toggleDrawer={toggleDrawer}
        alignment={alignment}
        handleAligmentChange={handleAligmentChange}
        handleShare={handleShare}
        handleClickOpen={handleClickOpen}
        setTimer={setTimer}
        query={query}
        onQueryChange={onQueryChange}
        onQuerySubmit={onQuerySubmit}
        loading={loading}
        sessionId={sessionId}
      />

      {conversationHistory.length > 1 && (
        <RatingComponent handleShare={addToDrawer} />
      )}
    </Box>
  );
};
