import React, { useState, useEffect, useCallback } from "react";
import Fuse from "fuse.js";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  debounce,
  CircularProgress,
  Fade,
  Divider,
} from "@mui/material";
import { logCustomEvent } from "../utils/Analytics";
import SearchIcon from "@mui/icons-material/Search";

const SearchComponent = ({ sessionId }: { sessionId: string }) => {
  const [terms, setTerms] = useState([]);
  const [fuse, setFuse] = useState<any>(null);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const renderAppearances = (appearances: string | string[]) => {
    try {
      console.log("appearances", appearances);
      if (typeof appearances === "string") {
        return appearances.replace("[", "").replace("]", "");
      }

      return appearances.map(
        (appearance, i) => appearance + (i < appearances.length - 1 ? ", " : "")
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    // Fetch the data
    fetch(
      "https://transcriptionsdata.blob.core.windows.net/transcripts/processed_test_2.json"
    )
      .then((response) => response.json())
      .then((data) => {
        const allTerms = data.flatMap((item: any) =>
          item.terms.map((term: any) => ({
            ...term,
            subject: item.subject,
            lectureName: item.name,
            appearnces: term.appearnces,
            relatedTerms: term.related_terms,
          }))
        );
        setTerms(allTerms);

        const fuseInstance = new Fuse(allTerms, {
          keys: [
            { name: "term", weight: 2 },
            { name: "definition", weight: 0.25 },
            { name: "relatedTerms", weight: 0.2 },
          ],
          threshold: 0.25,
          includeScore: true,
        });

        setFuse(fuseInstance);
      });
  }, []);

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      if (value.length > 0 && fuse) {
        setIsLoading(true);
        const results = fuse.search(value);
        setSearchResults(results.map((result: any) => result.item));
        setIsLoading(false);
        logCustomEvent("searched_term", {
          prompt: value,
          sessionId: sessionId,
        });
      } else {
        setIsLoading(false);
        setSearchResults([]);
      }
    }, 1000),
    [fuse]
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;
    setIsLoading(true);
    setSearchTerm(searchValue);
    debouncedSearch(searchValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <TextField
        fullWidth
        variant="filled"
        value={searchTerm}
        onChange={handleSearch}
        placeholder="הקלידו מונח לחיפוש..."
        autoFocus
        InputProps={{
          style: {
            textAlign: "right",
            fontSize: "1.1rem",
            fontWeight: "500",
          },
          startAdornment: isLoading ? (
            <CircularProgress size={20} sx={{ color: "white" }} />
          ) : (
            <SearchIcon
              sx={{
                color: "white",
                mr: 1,
                fontSize: "1.3rem",
              }}
            />
          ),
        }}
        sx={{
          "& .MuiFilledInput-root": {
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            color: "white",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.25)",
            },
            "&.Mui-focused": {
              backgroundColor: "rgba(0, 0, 0, 0.3)",
            },
            "&::before": {
              borderBottom: "2px solid rgba(255, 255, 255, 0.5)",
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottom: "2px solid rgba(255, 255, 255, 0.7)",
            },
            "&::after": {
              borderBottom: "2px solid white",
            },
          },
          "& .MuiInputBase-input": {
            "&::placeholder": {
              color: "rgba(255, 255, 255, 0.8)",
              opacity: 1,
              fontSize: "1rem",
              left: "auto",
              direction: "rtl",
            },
          },
          "& .MuiInputLabel-root": {
            color: "rgba(255, 255, 255, 0.8)",
            fontSize: "1.1rem",
            left: "auto",
            direction: "rtl",
          },
          "& label": {
            left: "auto",
            direction: "rtl",
            color: "white",
          },

          "& .MuiFilledInput-input": {
            direction: "rtl",
            marginBottom: "10px",
          },
        }}
      />

      <Box
        sx={{
          maxHeight: "60vh",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: "rgba(0, 0, 0, 0.1)",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "rgba(255, 255, 255, 0.4)",
            borderRadius: "4px",
            "&:hover": {
              background: "rgba(255, 255, 255, 0.5)",
            },
          },
        }}
      >
        <List>
          {searchResults.map((result: any, index: number) => (
            <ListItem
              key={index}
              sx={{
                mb: 1,
                bgcolor: "rgba(255, 255, 255, 0.98)",
                borderRadius: 1,
                textAlign: "right",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                transition: "transform 0.2s ease-in-out",
                "&:hover": {
                  transform: "translateY(-2px)",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                },
              }}
            >
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#1976d2",
                      fontWeight: 600,
                      fontSize: "1.2rem",
                    }}
                  >
                    {result.term}
                  </Typography>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      variant="body1"
                      paragraph
                      sx={{
                        color: "rgba(0, 0, 0, 0.87)",
                        fontSize: "1rem",
                        mt: 1,
                      }}
                    >
                      {result.definition}
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    <Typography
                      variant="body2"
                      sx={{
                        color: "rgba(0, 0, 0, 0.7)",
                        fontSize: "0.9rem",
                        mb: 1,
                      }}
                    >
                      הרצאה: {result.lectureName} | נושא: {result.subject}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "rgba(0, 0, 0, 0.7)",
                        fontSize: "0.9rem",
                        mb: 1,
                      }}
                    >
                      הופעות: {renderAppearances(result.apperances)}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "rgba(0, 0, 0, 0.7)",
                        fontSize: "0.9rem",
                      }}
                    >
                      מונחים קשורים: {result?.relatedTerms?.join(", ")}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          ))}
        </List>

        <Fade
          in={searchTerm.length > 0 && !isLoading && searchResults.length === 0}
        >
          <Typography
            variant="body1"
            align="center"
            sx={{
              mt: 2,
              color: "white",
              fontSize: "1.1rem",
              fontWeight: 500,
            }}
          >
            לא נמצאו תוצאות
          </Typography>
        </Fade>
      </Box>
    </Box>
  );
};

export default SearchComponent;
